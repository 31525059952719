.boxstyle{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    border: none;
    box-shadow: 24;
    padding: 15px 20px;
}

.modalbutton{
    border:none; 
    /* margin-left: auto;
    margin-right: 0;
    margin-top: 15px;
    margin-bottom: auto; */
    border-radius:50%;
    height:30px;
    width:30px;
    font-size: 18px;
    text-align: center;
    background-color:#FF2934;
    color:white;
}
.modalbutton:hover{
    cursor: pointer;
}
.addbtn{
    background-color: #3CB532;
    color: white;
    width: 100%;
    height: 34px;
    border: none;
}
.clndr{
    height: 100%;
    width: 100%;
}
