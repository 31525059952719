.containerBox {
  width: calc(100% - 140px);
}

.contentBox {
  flex: 1;
  display: flex;
}

.leftContainer {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.leftContainer h1 {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  align-self: center;
}

.rightConainer {
  width: 73%;
}

.inputBox {
  height: 100%;
  width: 100%;
  padding: 0px 10px;
  color: rgba(0, 0, 0, 0.65);
  border: none;
  outline: none;
  user-select: none;
  font-family: Inter;
  font-size: 22px;
  font-weight: 400;
}

.firstRow {
  width: 100%;
  display: flex;
  gap: 20px;
}

.rekordId {
  height: 50px;
  width: 200px;
}

.userType {
  height: 50px;
  width: 710px;
}

.secondRow {
  width: 100%;
  display: flex;
  gap: 22px;
}

.admissionNo {
  height: 50px;
  width: 150px;
}

.admissionNo input::placeholder {
  opacity: 0.5;
}

.firstName {
  height: 50px;
  width: 200px;
}

.middleName {
  height: 50px;
  width: 220px;
}

.lastName {
  height: 50px;
  width: 180px;
}

.thirdRow {
  width: 100%;
  display: flex;
  gap: 22px;
}

.gender {
  height: 50px;
  width: 130px;
}

.studentClass {
  height: 50px;
  width: 200px;
}

.studentRollNo {
  height: 50px;
  width: 100px;
}

.studentRollNo input::placeholder {
  opacity: 0.4;
}

.studentAdmissionDate {
  height: 50px;
  width: 180px;
}

.studentEmail {
  height: 50px;
  width: 240px;
}

.teacherRole {
  height: 50px;
  width: 170px;
}

.teacherClass {
  height: 50px;
  width: 130px;
}

.teacherSubject {
  height: 50px;
  width: 200px;
}

.teacherAdmissionDate {
  height: 50px;
  width: 180px;
}

.teacherEmail {
  height: 50px;
  width: 240px;
}

.staffRole {
  height: 50px;
  width: 200px;
}

.staffAdmissionDate {
  height: 50px;
  width: 200px;
}

.staffEmail {
  height: 50px;
  width: 260px;
}

.fourthRow {
  width: 100%;
  display: flex;
  gap: 20px;
}

.dob {
  height: 50px;
  width: 200px;
}

.fatherName {
  height: 50px;
  width: 200px;
}

.motherName {
  height: 50px;
  width: 240px;
}

.gaurdianName {
  height: 50px;
  width: 300px;
}

.primaryContactNo {
  height: 50px;
  width: 250px;
}

.secondaryContactNo {
  height: 50px;
  width: 250px;
}

.fifthRow {
  width: 100%;
  display: flex;
  gap: 20px;
}

.fatherContactNo {
  height: 50px;
  width: 200px;
}

.motherContactNo {
  height: 50px;
  width: 200px;
}

.gaurdianContactNo {
  height: 50px;
  width: 280px;
}

.sixthRow {
  height: 50px;
  width: 680px;
}

.seventhRow {
  height: 50px;
  width: 680px;
}

.btnContainer {
  display: flex;
  gap: 20px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
}

.adminUserBtn {
  width: 171px;
  height: 45px;
}

@media (max-width: 1280px) {
  .contentBox {
    flex-direction: column;
  }

  .leftContainer {
    width: 100%;
    height: 100%;
    gap: 0px;
    padding: 20px 20px;
  }

  .leftContainer h1 {
    align-self: flex-start;
  }

  .rightConainer {
    width: 100%;
  }

  .firstRow {
    flex-wrap: wrap;
  }
  .rekorId {
    min-width: 160px;
    flex: 1;
  }

  .userType {
    min-width: 240px;
    flex: 1;
  }

  .secondRow {
    flex-wrap: wrap;
  }

  .admissionNo {
    min-width: 160px;
    flex: 1;
  }

  .firstName {
    min-width: 160px;
    flex: 1;
  }

  .middleName {
    min-width: 200px;
    flex: 1;
  }

  .lastName {
    min-width: 160px;
    flex: 1;
  }

  .thirdRow {
    flex-wrap: wrap;
  }

  .studentClass {
    min-width: 200px;
    flex-grow: 1;
  }

  .studentRollNo {
    min-width: 100px;
    flex-grow: 1;
  }

  .studentAdmissionDate {
    min-width: 180px;
    flex-grow: 1;
  }
  .studentEmail {
    min-width: 240px;
    flex-grow: 1;
  }

  .teacherRole {
    min-width: 200;
    flex-grow: 1;
  }

  .staffRole {
    min-width: 200;
    flex-grow: 1;
  }

  .teacherClass {
    min-width: 100px;
    flex-grow: 1;
  }

  .teacherSubject {
    min-width: 200;
    flex-grow: 1;
  }

  .teacherAdmissionDate {
    min-width: 180px;
    flex-grow: 1;
  }
  .teacherEmail {
    min-width: 240px;
    flex-grow: 1;
  }

  .staffAdmissionDate {
    min-width: 180px;
    flex-grow: 1;
  }

  .staffAdmissionDate {
    min-width: 180px;
    flex-grow: 1;
  }

  .staffEmail {
    min-width: 240px;
    flex-grow: 1;
  }

  .fourthRow {
    flex-wrap: wrap;
  }

  .fatherName {
    min-width: 160px;
    flex-grow: 1;
  }

  .motherName {
    min-width: 160px;
    flex-grow: 1;
  }

  .gaurdianName {
    min-width: 240px;
    flex-grow: 1;
  }

  .fifthRow {
    flex-wrap: wrap;
  }

  .dob {
    min-width: 200px;
    flex: 1;
  }

  .primaryContactNo {
    min-width: 160px;
    flex-grow: 1;
  }

  .secondaryContactNo {
    min-width: 160px;
    flex-grow: 1;
  }

  .fatherContactNo {
    min-width: 200px;
    flex: 1;
  }

  .motherContactNo {
    min-width: 200px;
    flex: 1;
  }

  .gaurdianContactNo {
    min-width: 240px;
    flex: 1;
  }

  .sixthRow {
    min-height: 50px;
    width: 100%;
  }

  .seventhRow {
    min-height: 50px;
    width: 100%;
  }

  .btnContainer {
    gap: 20px;
  }

  .adminUserBtn {
    width: auto;
    height: auto;
    padding: 10px 20px;
  }
}

@media (max-width: 400px) {
  .containerBox {
    width: 100%;
  }

  .btnContainer {
    gap: 10px;
  }

  .adminUserBtn {
    width: auto;
    height: auto;
    padding: 4px 8px;
  }
}
