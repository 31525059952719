@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@700&display=swap');

.Main{
    /* margin: 20px 0px; */
    width: 100%; 
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}

.forDiv1{
    text-align: center;
    width: 530px;
}

label{  
        margin-top: 13%;
        text-align: left;
        display: block;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: black;
    }

.forInput{ 
    outline: none;
    margin-bottom: 6%;
    border: none; 
    border-bottom: 1px solid black;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 24px;
    width: 100%;
    }
    .forInput::placeholder{ 
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        }

.errmsg{
    color: red;
    text-align: center;
    margin-top: -3%;
    /* margin-bottom: 5%; */
}

.forBtn1{
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 30px;
    color: #FFFFFF;
    border: none;
    background: rgb(184, 183, 183);
    border-radius: 12px;
    width: 100%;
    height: 85px;
    
}
.forBtn2{
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 30px;
    color: #FFFFFF;
    border: none;
    background: #3742FA;
    box-shadow: 0px 4px 14px 1px #949AFF;
    border-radius: 12px;
    width: 100%;
    height: 85px;
    outline: none;
    
}

.heading{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    line-height: 59px;
    color: black;
}
.forP{
    text-align: center;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #4F4F4F;
}

.forP2{
    text-align: center;
    font-family: 'Work Sans', sans-serif;
    margin-top: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;

}

.forP2 a{
    text-decoration: none;
    color: #626262;
}

/* --OtpVerify css-- */

.OtpDiv{
    display:flex;
    justify-Content:center;
    gap: 20px;
}
.otp-field{
    outline: none;
    margin: 25px 0px 40px 0px;
    height: 42px;
    width: 38px;
    border: none;
    border-radius: 8px;
    text-align: center;
    font-size: 1.2rem;
    background: linear-gradient(360deg, #E5E9F1 -89.76%, #FFFFFF 100%);
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}
.OtpTimer button {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 23px;
    border: none;
    background: transparent;
}
.OtpTimer{
    text-align: center;
    margin-top: 35px;
}

.OtpTimer p{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #1E69DA;
}

/* --password css-- */


/* .password{
    margin: 75px 0px;
} */
.passform{
    margin-top: 60px;
    text-align: center;
    width: 530px;
}
.passlabel{
    text-align: left;
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: black;
    margin: 0;
}
.err1{
    margin-top: -6%;
    color: red;
    display: block;
    text-align: left;
    margin-bottom: 3%;
}   
.err{
    color: red;
    display: block;
    text-align: left;
    margin-top: -12%;
    margin-bottom: 5%;
} 

.passfor_btn{
    margin-top: 50px;
} 
.passdiv{
    position: relative;
  }
  
  .pass_btn {
    position: absolute;
    top: -40%;
    right: 5px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #888;
  }
  
  .passforP2{
    padding-top: 30%;
  }

  /* --Media query-- */
  
@media screen and (max-width: 620px){
    
    .forDiv1,.passform{
        width: 441px !important;
    }
    }
    @media screen and (max-width: 520px){
        
        .forDiv1,.passform{
            width: 400px !important;
        }
        }
        @media screen and (max-width: 420px){
        
            .forDiv1,.passform{
                width: 300px !important;
            }
            h1{
                font-size: 30px;
            }
            .forP{
                font-size: 15px;
            }
            .forBtn1,.forBtn2{
                font-size: 16px;
                height: 65px;
            }
            .otp-field{
                width: 29px !important;
                height: 36px !important;
            }
            }