/* .fc-header-toolbar {
  display: none !important;
} */

.fc-scroller {
    height: 100% !important;
}

.popoverStyle {
    z-index: 99999 !important;
}

.acdClndr {
    width: 100%;
    height: 90%;
    border: 1px solid #E7E7E7;
    box-sizing: border-box;
    border-radius: 10px;
    -moz-box-shadow: 0 0 5px #999;
    -webkit-box-shadow: 0 0 5px #999;
    box-shadow: 0 0 5px #999;
    padding: 2px 10px;
    background-color: #FFFFFF;
    overflow: hidden;
}

.acdiv1 {
    background-color: #f9f9f9;
    height: 40px;
    align-items: center;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid rgb(235, 234, 234);
}

.acd1d1 {
    padding: 8px 10px;
    align-items: center;
    display: flex;
}

.acd1d1 span {
    width: fit-content;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
}

.acd1d2 {
    min-width: fit-content;
    display: flex;
    margin-left: auto;
    margin-right: 0px;

}

.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 14px !important;
}

.acdiv2 {
    overflow: hidden;
}

.react-calendar__navigation {
    display: none !important;
}

.react-calendar {
    width: 100% !important;
    border: none !important;
    padding-top: 22px !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
    height: auto !important;
    color: #222 !important;
    line-height: 1.125em !important;
}

.react-calendar__month-view__days__day--weekend {
    color: #222 !important;
    border-right: none !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575 !important;
}

.react-calendar__tile {
    font-size: 13px !important;
    padding: 5px 45px 24px 10px !important;
    text-align: start !important;
}

abbr[title] {
    text-decoration: none !important;
    cursor: auto !important;
}

.react-calendar button {
    border-bottom: 1px solid #E7E7E7 !important;
    border-right: 1px solid #E7E7E7 !important;
}

.react-calendar__month-view__weekdays {
    text-align: start !important;
    font-size: 15px !important;
}

@media (max-width: 674px) {
    .react-calendar__tile {
        padding: 5px 25px 26px 10px !important;
    }

    .react-calendar__month-view__weekdays {
        font-size: 13px !important;
    }
}

@media screen and (max-width:1080px) {
    .acdClndr {
        height: 450px;
        width: 100%;
        display: block;
    }
}

@media (max-width: 822px) {
    .acd1d1 span {
        font-size: 16px;
    }
}

@media (max-width: 684px) {
    .acd1d1 span {
        font-size: 14px;
    }
}

@media (max-width: 388px) {

    .css-1ijltce,
    .css-f0eqg5 {
        min-width: 70px !important;
    }

    .acdiv1 {
        height: 52px;
    }
}

@media (max-width:286px) {

    .css-1ijltce,
    .css-f0eqg5 {
        min-width: 50px !important;
    }
}

/* CustomFullCalendar.css */
/* .fc-toolbar {
    background-color: #8b1313;
    border-bottom: 1px solid #ddd;
} */

.fc-day {
    color: #333;
}

.fc-daygrid-event {
    height: auto;
    width: auto;
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
}

.fc-daygrid-event:hover {
    background-color: #b3007a;
    border-color: #b30003;
}

.popoverStyle {
    max-width: 200px;
    /* Example: Change max width */
    background-color: #f8f9fa;
    /* Example: Change background color */
    border: 1px solid #ddd;
    /* Example: Change border */
}

.popoverStyle .popover-header {
    background-color: #221bd3;
    color: #fff;
}

.popoverStyle .popover-body {
    color: #333;
}

.fc-day-header .fc-day-header-name {
    color: #00698f;
    /* change the color to your desired value */
}

/* Add other custom styles here */
/* Change the color of all day cells */
.fc-daygrid-day {
    /* background-color: #af4040; */
    font-size: 15px;
    /* Light gray background */
    /* color: #333 !important; */
    /* Dark text color */
}

/* Change the color of weekends (Saturday and Sunday) */
.fc-day-sat,
.fc-day-sun {
    /* background-color: #e0e0e0; */
    /* Slightly darker gray for weekends */
    /* color: #333; */
    /* Dark text color */
}

/* Change the color of specific days (e.g., Monday) */
.fc-day {
    /* background-color: #efe5ee; */
    /* Light blue background for Mondays */
    /* color: #c71515 !important; */
    /* Dark text color */
}