.assigndiv {
  width: 90%;
  margin: 28px auto auto 50px;
}

.para {
  font-size: 14px;
  color: #000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.div1 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.div2 {
  display: flex;
}

.para2 {
  font-family: Rubik !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  margin: 0;
  color: #000000;
}

.blck {
  display: flex;
  margin-left: 20px;
}

.anchor {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ff7575;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  margin-left: 20px;
}

.div3 {
  display: flex;
  margin-top: 50px;
}

.assignbtn {
  border: none;
  border-radius: 5px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  padding: 4px 10px;
  text-align: center;
  margin-right: 25px;
  background-color: #ffffff;
  color: #7e7e7e;
  font-weight: 300;
  line-height: normal;
}

.assignbtnfocus {
  font-weight: 400;
  background: #214df9;
  color: #ffffff;
}

.assigntime {
  border: none;
  border-radius: 5px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #7e7e7e;
  padding: 4px 10px;
  text-align: center;
  margin-right: 25px;
}

.assigntime:focus {
  background: #214df9;
  border-color: #214df9;
  border-radius: 5px;
  color: #ffffff;
}

.div4 {
  width: 100%;
}

.assignlayout {
  background: linear-gradient(
    180deg,
    #fdfdfd 0%,
    rgba(245, 245, 245, 0.92) 100%
  ) !important;
  border: 2px solid #a4a4a4 !important;
  border-radius: 5px !important;
  padding: 18px;
}

.assignheading {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 14px;
  color: #474747;
  margin: 0 0 0 20px;
  padding: 0;
}

.teacherlayout {
  background: linear-gradient(
    180deg,
    #fdfdfd 0%,
    rgba(245, 245, 245, 0.92) 100%
  );
  border: 2px solid #a4a4a4;
  border-radius: 5px;
  padding: 20px 46px 6px 46px;
}

.teacherhead {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 14px;
  color: #474747;
}

.tblayout {
  max-height: 300px;
  overflow-y: auto;
  background: #ffffff;
  border: 1px solid #a5a5a5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-top: 1px;
}

/*row css*/
.row {
  padding-left: 10px;
}

.tabCell {
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 30px !important;
  color: #545454 !important;
  border-bottom: none !important;
}

.cell2 {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #000000 !important;
  text-align: left !important;
  font-family: Lato !important;
  line-height: normal;
}

.cell2 button:enabled {
  border: none;
  background-color: #214df9;
  border-radius: 3px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #ffffff;
  padding: 3px 8px;
}

.cell2 button:disabled {
  cursor: not-allowed;
  color: #545454;
  background-color: #e2e0e0;
}

.cell3 {
  padding: 10px;
  margin: auto;
  text-align: left;
}

/* Dropdown css */
.ddslt {
  border-radius: 5px;
  font-size: 14px;
  padding: 2px 10px;
  border: 1px solid #414141;
  background: #fff;
  color: #414141;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ddopt {
  font-size: 14px;
  color: #202020;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 720px) {
  .assigndiv {
    width: 100%;
    padding: 28px;
    margin: 0;
  }
  .div2 {
    justify-content: space-between;
  }

  .para2 {
    margin-top: 0;
  }

  .blck {
    display: block;
  }

  .ddslt {
    width: 200px;
  }

  .anchor {
    position: absolute;
    top: 222px;
    margin: 0;
  }
}

@media (max-width: 324px) {
  .assigntime {
    margin-right: 10px;
  }
}
