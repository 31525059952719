.subjectsContainer {
  position: relative;
  padding: 20px 80px 20px 40px;
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.inputBox {
  height: 100%;
  width: 100%;
  padding: 0px 10px;
  color: rgba(0, 0, 0, 0.65);
  border: none;
  outline: none;
  user-select: none;
  font-family: Inter;
  font-size: 22px;
  font-weight: 400;
}

input::placeholder {
  font-family: Inter;
  font-size: 22px;
  font-weight: 400;
  font-style: italic;
  color: #909090;
  opacity: 0.65;
}

.searchBar{
  width: 350px;
}

.subjectName,
.subjectType,
.classNo,
.sections,
.subjectCode,
.NoOfChapters {
  height: 50px;
}

@media (max-width: 680px) {
  .subjectName {
    height: 50px;
    width: 100%;
  }

  .classNo {
    height: 50px;
    width: 100%;
  }

  .sections {
    height: 50px;
    width: 100%;
  }

  .subjectCode {
    height: 50px;
    width: 100%;
  }

  .NoOfChapters {
    height: 50px;
    width: 100%;
  }
}

@media (min-width: 1280px) {
  .subjectName {
    height: 50px;
    width: 560px;
  }

  .classNo {
    height: 50px;
    width: 250px;
  }

  .subjectCode {
    height: 50px;
    width: 250px;
  }

  .NoOfChapters {
    height: 50px;
    width: 309px;
  }
}
