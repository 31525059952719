.prncplinboxmail,.prncplcomposemail,.prncpldeletedmail,.prncpldraftmail,.prncplfvrtmail,.prncplsentmail{
    display: flex;
}
.prncplinboxmaild1,.prncplcomposemaild1,.prncpldeletedmaild1,.prncpldraftmaild1,.prncplfvrtmaild1,.prncplsentmaild1{
    width:25%;
    margin-top:25px;
}
.prncplinboxmaild2,.prncplcomposemaild2,.prncpldeletedmaild2,.prncpldraftmaild2,.prncplfvrtmaild2,.prncplsentmaild2{
    width:70%;
    background:#fff;
    margin-left:auto;
    margin-right:-20px;
    margin-top:-25px;
    padding-left:15px;
}
@media (max-width: 990px) {
    .prncplinboxmail,.prncplcomposemail,.prncpldeletedmail,.prncpldraftmail,.prncplfvrtmail,.prncplsentmail{
        flex-direction: column;
    }
    .prncplinboxmaild1,.prncplcomposemaild1,.prncpldeletedmaild1,.prncpldraftmaild1,.prncplfvrtmaild1,.prncplsentmaild1{
        width: 90%;
        margin: auto;
    }
    .prncplinboxmaild2,.prncplcomposemaild2,.prncpldeletedmaild2,.prncpldraftmaild2,.prncplfvrtmaild2,.prncplsentmaild2{
        width: 100%;
        margin: auto;
    }
}
.mailicon1,.mailicon2{
    color:#565656;
    margin-right:15px;
}
.mailicon3{
    color: #565656;
}
.mailicon1:hover{
    color: #FFE500;
}
.mailicon2:hover{
    color: #214DF9;
}
.mailicon3:hover{
    color: #FF2934;
}
/* .MuiTooltip-tooltip{
    background: transparent !important;
    color: rgba(0, 0, 0, 0.53) !important;
    font-size: 16px !important;
    margin-bottom: 4px !important;
} */
.prncplrestorebtn{
    background: #3CB532;
    border: none;
    color: white;
    padding: 8px 15px;
    border-radius: 5px;
    float: right;
    margin-top: 50px;
    margin-right: 20px;
}
.prncplrestorebtn:hover{
    cursor: pointer;
}
/* .rdw-editor-wrapper{
    display: flex;
    flex-direction: column-reverse;
}
.rdw-option-wrapper[title=Strikethrough]{
    display: none !important;
}
.rdw-option-wrapper[title=Monospace]{
    display: none !important;
}
.rdw-option-wrapper[title=Superscript]{
    display: none !important;
}
.rdw-option-wrapper[title=Subscript]{
    display: none !important;
}
.rdw-block-wrapper,
.rdw-list-wrapper,
.rdw-colorpicker-wrapper,
.rdw-link-wrapper,
.rdw-embedded-wrapper,
.rdw-emoji-wrapper,
.rdw-image-wrapper,
.rdw-remove-wrapper,
.rdw-history-wrapper{
    display: none !important;
} */
.prncplmailsendbtn{
    float: right;
    background: #214DF9;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 10px;
    font-size: 16px;
}
.prncplmailsendbtn:hover{
    cursor: pointer;
}
.selectmsgtype{
    display: flex;
    background: transparent;
    width: 100%;
    border: 1px solid transparent;
}
.selectmsgtype:hover{
    cursor: pointer;
}
.selectmsgtype:focus{
    background: #FFFFFF;
    border-radius: 10px;
    -moz-box-shadow: 0 0 5px #999;
    -webkit-box-shadow: 0 0 5px #999;
    box-shadow: 0 0 5px #999;
}
.prncplsearchBox{
    border-radius: 10px;
    background-color: #D1D8FF;
    height: 42px;
    border: none;
    width: 100%;
    margin-bottom: 18px;
}
.prncplsearchBox::placeholder{
    font-size: 18px;
    text-align: center;
};
