.transportContainer {
  position: relative;
  padding: 20px 80px 20px 40px;
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.inputBox {
  height: 100%;
  width: 100%;
  padding: 0px 10px;
  color: rgba(0, 0, 0, 0.65);
  border: none;
  outline: none;
  user-select: none;
  font-family: Inter;
  font-size: 22px;
  font-weight: 400;
}

input::placeholder {
  font-family: Inter;
  font-size: 22px;
  font-weight: 400;
  font-style: italic;
  color: #909090;
  opacity: 0.65;
}

.zone,
.routeName,
.vehicleNo,
.driverName,
.licenseNo,
.contactNo {
  height: 50px;
}

@media (max-width: 680px) {
  .zone,
  .routeName,
  .vehicleNo,
  .driverName,
  .licenseNo,
  .contactNo {
    height: 50px;
    width: 100%;
  }
}

@media (min-width: 1280px) {
  .zone {
    height: 50px;
    width: 160px;
  }

  .routeName {
    height: 50px;
    width: 340px;
  }

  .vehicleNo {
    height: 50px;
    width: 390px;
  }

  .driverName {
    height: 50px;
    width: 320px;
  }

  .licenseNo {
    height: 50px;
    width: 280px;
  }

  .contactNo {
    height: 50px;
    width: 280px;
  }
}
