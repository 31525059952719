.mainpage {
  display: flex;
}
.layout__leftSide {
  display: flex;
}
.Principallayout {
  width: 100%;
  margin-left: 145px !important;
}
@media (max-width: 720px) {
  .layout {
    width: auto;
    margin-left: 0px;
  }
  .Principallayout {
    margin-left: 0px !important;
  }
  .layout__rightSide {
    margin-left: 0px !important;
  }
  .layout__main {
    display: block;
  }
}
