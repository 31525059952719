.tchrattendance {
  width: 65%;
  height: 100%;
  margin: auto;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: 10px;
  -moz-box-shadow: 0 0 5px #999;
  -webkit-box-shadow: 0 0 5px #999;
  box-shadow: 0 0 5px #999;
  padding: 0px;
  background-color: #ffffff;
  overflow: hidden;
}

.tchrattendance-body {
  /* Add a new class for the body to control the scrollbar */
  height: 100%; /* Height after considering the header's height */
  overflow-y: auto; /* Show the scrollbar here */
}

.tchrattendance-body::-webkit-scrollbar {
  width: 8px;
}

.tchrattendance-body::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
  overflow-x: auto;
}

.tchrattendanceheader {
  padding: 12px 10px;
  align-items: center;
  display: flex;
}

.tchrattendanceweekly:hover {
  cursor: pointer;
}

.active {
  background-color: #e0dddd !important;
  border-radius: 5px;
  box-shadow: 0px 1px 5px rgba(33, 33, 33, 0.48);
  padding: 4px;
}

.tchrattendanceweekly {
  border: none;
  font-size: 14px;
  padding: 4px;
  background-color: transparent;
  color: #414141;
}

@media (max-width: 1080px) {
  .tchrattendance {
    width: 100%;
    height: 380px;
    /* margin-left: 34px; */
  }
}
