.containerBox {
    width: calc(100% - 140px);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    overflow: auto;
  }
  
  .topNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
  }
  
  .breadcrumb {
    display: flex;
    align-items: center;
    color: #4d4d4d;
  }
  
  .breadcrumbText {
    font-family: "Roboto";
    font-size: 18px;
    font-weight: 400;
  }
  
  .breadcrumbText.underline {
    text-decoration: underline;
  }
  
  .contentBox {
    display: flex;
    flex: 1;
  }
  
  
  .contentBox {
    flex: 1;
    display: flex;
  }
  
  .leftContainer {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
  
  .leftContainer h1 {
    font-family: "Poppins";
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    align-self: center;
  }
  
  .profileImageContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  
  
  .profilePlaceholder {
    height: 132px;
    width: 132px;
    border-radius: 50%;
    background-color: #f5f5f5;
    display: grid;
    place-content: center;
    font-family: "Rubik";
    font-weight: 500;
    font-size: 48px;
    color: #959595;
  }
  
  .rightContainer {
    width: 73%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .profileDetails {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .profileRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }
  
  .profileRow strong {
    font-weight: 600;
    color: #000;
  }
  
  
  .backButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
  }
  
  .backButtonText {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 24px;
    color: #414141;
  }
  
  .inputBox {
    height: 100%;
    width: 100%;
    padding: 0px 10px;
    color: rgba(0, 0, 0, 0.65);
    border: none;
    outline: none;
    user-select: none;
    font-family: "Inter";
    font-size: 22px;
    font-weight: 400;
  }
  .firstRow {
    width: 100%;
    display: flex;
    gap: 20px;
  }
  
  .rekordId {
    height: 50px;
    width: 200px;
  }
  
  .userType {
    height: 50px;
    width: 710px;
  }
  
  .secondRow {
    width: 100%;
    display: flex;
    gap: 22px;
  }
  
  .admissionNo {
    height: 50px;
    width: 200px;
  }
  
  .firstName {
    height: 50px;
    width: 200px;
  }
  
  .middleName {
    height: 50px;
    width: 260px;
  }
  
  .lastName {
    height: 50px;
    width: 200px;
  }
  
  .thirdRow {
    width: 100%;
    display: flex;
    gap: 22px;
  }
  
  .studentClass {
    height: 50px;
    width: 200px;
  }
  
  .studentRollNo {
    height: 50px;
    width: 100px;
  }
  
  .studentAdmissionDate {
    height: 50px;
    width: 180px;
  }
  
  .studentEmail {
    height: 50px;
    width: 240px;
  }
  
  .teacherRole {
    height: 50px;
    width: 220px;
  }
  
  .teacherClass {
    height: 50px;
    width: 80px;
  }
  
  .teacherSubject {
    height: 50px;
    width: 200px;
  }
  
  .teacherAdmissionDate {
    height: 50px;
    width: 180px;
  }
  
  .teacherEmail {
    height: 50px;
    width: 240px;
  }
  
  .staffRole {
    height: 50px;
    width: 200px;
  }
  
  .staffAdmissionDate {
    height: 50px;
    width: 200px;
  }
  
  .staffEmail {
    height: 50px;
    width: 260px;
  }
  
  .fourthRow {
    width: 100%;
    display: flex;
    gap: 20px;
  }
  
  .dob {
    height: 50px;
    width: 200px;
  }
  
  .primaryContactNo {
    height: 50px;
    width: 250px;
  }
  
  .secondaryContactNo {
    height: 50px;
    width: 250px;
  }
  
  .fatherName {
    height: 50px;
    width: 200px;
  }
  
  .motherName {
    height: 50px;
    width: 240px;
  }
  
  .guardianName {
    height: 50px;
    width: 300px;
  }
  
  .fifthRow {
    width: 100%;
    display: flex;
    gap: 20px;
  }
  
  .fatherContactNo {
    height: 50px;
    width: 200px;
  }
  
  .motherContactNo {
    height: 50px;
    width: 200px;
  }
  
  .guardianContactNo {
    height: 50px;
    width: 280px;
  }
  
  .sixthRow {
    height: 50px;
    width: 680px;
  }
  
  .seventhRow {
    height: 50px;
    width: 680px;
  }
  
  .btnContainer {
    display: flex;
    gap: 20px;
    font-family: Rubik;
    font-size: 18px;
    font-weight: 500;
  }
  
  .adminEditBtn {
    width: 171px;
    height: 45px;
  }
  
  @media (max-width: 1280px) {
    .contentBox {
      flex-direction: column;
    }
  
    .leftContainer {
      width: 100%;
      height: 100%;
      gap: 0px;
      padding: 20px 20px;
    }
  
    .leftContainer h1 {
      align-self: flex-start;
    }
  
    .rightConainer {
      width: 100%;
    }
  
    .firstRow {
      flex-wrap: wrap;
    }
    .rekorId {
      min-width: 160px;
      flex: 1;
    }
  
    .userType {
      min-width: 240px;
      flex: 1;
    }
  
    .secondRow {
      flex-wrap: wrap;
    }
  
    .admissionNo {
      min-width: 160px;
      flex: 1;
    }
  
    .firstName {
      min-width: 160px;
      flex: 1;
    }
  
    .middleName {
      min-width: 200px;
      flex: 1;
    }
  
    .lastName {
      min-width: 160px;
      flex: 1;
    }
  
    .thirdRow {
      flex-wrap: wrap;
    }
  
    .studentClass {
      min-width: 200px;
      flex-grow: 1;
    }
  
    .studentRollNo {
      min-width: 100px;
      flex-grow: 1;
    }
  
    .studentAdmissionDate {
      min-width: 180px;
      flex-grow: 1;
    }
    .studentEmail {
      min-width: 240px;
      flex-grow: 1;
    }
  
    .teacherRole {
      min-width: 200;
      flex-grow: 1;
    }
  
    .staffRole {
      min-width: 200;
      flex-grow: 1;
    }
  
    .teacherClass {
      min-width: 100px;
      flex-grow: 1;
    }
  
    .teacherSubject {
      min-width: 200;
      flex-grow: 1;
    }
  
    .teacherAdmissionDate {
      min-width: 180px;
      flex-grow: 1;
    }
    .teacherEmail {
      min-width: 240px;
      flex-grow: 1;
    }
  
    .staffAdmissionDate {
      min-width: 180px;
      flex-grow: 1;
    }
  
    .staffAdmissionDate {
      min-width: 180px;
      flex-grow: 1;
    }
  
    .staffEmail {
      min-width: 240px;
      flex-grow: 1;
    }
  
    .fourthRow {
      flex-wrap: wrap;
    }
  
    .fatherName {
      min-width: 160px;
      flex-grow: 1;
    }
  
    .motherName {
      min-width: 160px;
      flex-grow: 1;
    }
  
    .guardianName {
      min-width: 240px;
      flex-grow: 1;
    }
  
    .fifthRow {
      flex-wrap: wrap;
    }
  
    .dob {
      min-width: 200px;
      flex: 1;
    }
  
    .fatherContactNo {
      min-width: 200px;
      flex: 1;
    }
  
    .motherContactNo {
      min-width: 200px;
      flex: 1;
    }
  
    .guardianContactNo {
      min-width: 240px;
      flex: 1;
    }
  
    .sixthRow {
      min-height: 50px;
      width: 100%;
    }
  
    .seventhRow {
      min-height: 50px;
      width: 100%;
    }
  
    .btnContainer {
      gap: 20px;
    }
  
    .adminEditBtn {
      width: auto;
      height: auto;
      padding: 10px 20px;
    }
  }
  
  @media (max-width: 400px) {
    .containerBox {
      width: 100%;
    }
  
    .btnContainer {
      gap: 10px;
    }
  
    .adminEditBtn {
      width: auto;
      height: auto;
      padding: 4px 8px;
    }
  }
  