@import url('https://fonts.googleapis.com/css2?family=Lato&family=Poppins:wght@500&family=Roboto&family=Rubik&display=swap');

.statusstyle1{
    background: #3CB532; 
    border: none; 
    font-family: Rubik; 
    font-style: normal; 
    font-weight: 500; 
    font-size: 16px; 
    line-height: 17px; 
    color: white; 
    padding: 8px;
}
.statusstyle2{
    background: #FFFFFF;
    border: 1px solid #5647B2;
    box-sizing: border-box; 
    font-family: Rubik;
    font-style: normal; 
    font-weight: 500;
    font-size: 16px; 
    line-height: 17px; 
    color: #5647B2; 
    padding: 8px;
}
.statusstyle2:hover{
    cursor: pointer;
}
.actionbtns{
    display: flex;
}
.homeworkdeletebtn{
    background: #CF232C;
    border: none;
    box-sizing: border-box; 
    border-radius: 5px;
    font-family: Rubik;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    color: white;
    padding: 8px 15px;
}
.homeworkdeletebtn:hover{
    cursor: pointer;
}
@media (max-width:990px) {
    .actionbtns{
        flex-direction: column;
    }
}