.main__container {
    display: flex;
    padding: 10px;
    gap: 2;
    width: 100%;
}

.left__container {
    /* background-color: rgb(220, 223, 243) !important; */
    height: 610px;
    margin-top: 30px;
    margin-left: 10px;
    box-shadow: 0px 1px 5px rgba(33, 33, 33, 0.48);
    border-radius: 10px;
    padding: 15px !important;
    width: 20%;

}


.right__container {
    /* background-color: rgb(187, 172, 172); */
    height: 100%;
    width: 80%;
    padding: 10px;
    margin-left: 20px;
    margin-right: 20px;
}

@media (max-width: 1080px) {
    .left__container {
        width: 30%;
    }

    .right__container {
        width: 65%;
    }
}

@media (max-width: 720px) {
    .left__container {
        margin-left: 10px;
    }

    .right__container {
        margin-right: 0;
    }
}

@media (max-width:578px) {
    .main__container {
        display: block;
        justify-content: center;
    }

    .left__container {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .right__container {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }
}

.upper-container {
    /* background-color: white !important; */
    /* border: 2px solid red !important; */
    background-color: red;
}

/* .div1 {
    height: 76% !important;
    display: flex;
    width: 100%;
    margin: 10px;
} */