.prncpltchrattendancefilters{
    display:flex;
    gap: 10px;
    align-items: center;
    margin-top:20px;
    margin-bottom:25px;
}
.prncpltchrattendancedetails{
    -moz-box-shadow: 0 0 5px #999;
    -webkit-box-shadow: 0 0 5px #999;
    box-shadow: 0 0 5px #999; 
    background:white;
    border-radius:5px;
    margin:0px 10px;
}

.attendancebtn{
    border-radius: 50%;
    height: 32px;
    width: 32px;
    margin: 5px;
    border: none;
}
.attendancebtn1{
    background-color: #3CB532;
    color: white;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    margin: 5px;
    border: none;
}
.attendancebtn2{
    background-color: #FF2934;
    color: white;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    margin: 5px;
    border: none;
}
.attendancebtn3{
    background-color: #FFDD9C;
    color: white;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    margin: 5px;
    border: none;
}
.prncpltchrattendancediv2{
    display: flex;
}
.prncpltchrattendanceleftdiv{
    flex: 0.65;
}
.prncpltchrattendancerightdiv{
    flex:0.35;
    text-align:left;
    /* margin-top:-14px; */
}

.input1,.input2{
    margin-right: 40px;
}

.input1[type="radio"]:checked:before{
    background-color: #3CB532;
    color: white;
}
.input2[type="radio"]:checked:before{
    background-color: #FF2934;
    color: white;
}
.input1::before{
        content: 'P';
        font-size: 16px;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        border: none;
        background: #C0C0C0;
        padding: 7px 10px;
        color: #474747;
}

.input2::before{
    content: 'AB';
    border-radius: 50%;
    height: 32px;
    width: 32px;
    border: none;
    background-color: #C0C0C0;
    padding: 8px 6px;
    font-size: 16px;
    color: #474747;
}
.input3::before{
    content: 'L';
    border-radius: 50%;
    height: 32px;
    width: 32px;
    border: none;
    background-color: #C0C0C0;
    padding: 7px 11px;
    font-size: 16px;
    color: #474747;
}

@media (max-width:720px) {
    /* .prncpltchrattendancefilters{
        text-align: left;
        padding: 0;
    } */
    .prncpltchrattendancediv2{
        flex-direction: column;
    }
    .prncpltchrattendancediv2>div{
        width: 100%;
    }
    
    .prncpltchrattendancediv2{
        flex-direction: column;
    }
    .prncpltchrattendancerightdiv{
        margin-top: 10px;
        width: 100%;
    }
    .prncpltchrattendanceleftdiv,.prncpltchrattendancedetails{
        width: 100%;
        margin: 0;
    }
    
}


.attendance-summary{
    display: flex;
    gap: 16px;
    margin-top: 20px;

}

.attendance-table{
    width:100%;
    border: 1px solid grey !important;
}

.attendance-stats{
    width:100%;
    gap:16px;
    display: grid;
}


.attendance-box{
    background-color: white;
   box-shadow: 0 0 5px #999;
   border: 1px solid grey

}

.attendance-box p{
    border-bottom: 1px solid grey;
}

.attendance-filters{
    display: flex;
    align-items: left;
    gap:16px
}