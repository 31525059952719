.sidebarbtn {
  display: none;
  padding: 5px 7px;
  background-color: #fff;
  font-size: 20px;
  margin: 33px 10px;
  z-index: 3;
  border: none !important;
}
.styleexam{
    /* position: relative; */
    height: fit-content !important;
    flex-direction: column;
    gap: 3px;
    /* margin-bottom: 1px; */
    align-items: flex-start !important;
    /* margin-top: -15px; */
}
.logoutbtn:hover {
  cursor: pointer;
}
@media (max-width:720px) {
    .sidebarbtn {
    display: block;
    z-index: 3;
    margin: 0 !important;
    padding-top: 34px !important;
    padding-bottom: 33px !important;
    padding-left: 12px !important;
    padding-right: 10px !important;
  }

  .sidebar {
    z-index: 4;
  }

  .sidebaritem:hover {
    border: none;
    cursor: pointer;
    line-height: 52px;
  }
  .logo-text {
    margin-bottom: 0px;
  }

  .logo-company {
    margin-top: 92px;
  }

  .sidebardiv {
    overflow-y: auto;
  }

  .logoutbtn {
    display: none !important;
  }
  .Sidebarlist {
    margin-bottom: 15px;
  }
}
