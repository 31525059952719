.folder {
    text-align: center;
    width: 10%;
    margin-right: 4%;
    display: inline-block;
    width: 150px;
    margin-top: 20px;
}

.folder>a>button {
    background: url("./file-and-folder.png");
    width: 140px;
    height: 140px;
    border: none;
}

.folder>button:hover {
    cursor: pointer;
}

.folder h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

}

.crossbtn {
    border: none;
    margin-left: auto;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    font-size: 18px;
    text-align: center;
    background-color: #FF2934;
    color: white;
}

.crossbtn:hover {
    cursor: pointer;
}

.foldertable {
    display: flex;
    margin: 50px 0 0 40px;
    flex-direction: column;
}

@media screen and (max-width: 750px) {
    .foldertable {
        justify-content: center;
    }
}