
@import url("https://fonts.googleapis.com/css2?family=Lato:&family=Poppins:wght@100;400&display=swap");

.nextPageBtn{
  float: right;
  background: #214DF9;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 10px;
  font-size: 16px;
}
.nextPageBtn:hover{
  cursor: pointer;
}

.Mail {
  display: flex;
  gap: 10px;
  height: 100%;
}

.Maild1 {
  width: 30%;
  height: 550px;
  overflow-y: auto;
  background-color: #f8f9ff;
  padding-top: 56px;
  padding-left: 12px;
  padding-right: 12px;
}

.Maild1::-webkit-scrollbar {
  display: none;
}

.Maild2 {
  width: 70%;
  height: 600px;
  overflow-y: auto;
  background: #fff;
  padding-top: 22px;
  padding-left: 15px;
}

.attachments {
  list-style: none;
  padding: 0;
}
.attachments .attachchild {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f1f1f1;
  border-radius: 5px;
  width: 200px;
  padding: 5px;
  margin: 5px;
}

.attachments div li {
  border: none !important;
  cursor: pointer !important;
}

.MessageHead {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  margin-right: 80px;
  align-items: center;
}

.Messagediv2 ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.ContactSuggestion {
  position: relative;
}

.ContactSuggestion li {
  padding: 0px 10px 0px 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: #fff;
  border: 0.5px solid rgb(234, 233, 233);
  border-radius: 5px;
  align-items: center;
  gap: 10px;
}

.ContactSuggestion li:hover {
  background-color: #cdcecf;
}
.ContactSuggestion li p {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 12px;
  font-style: normal;
  line-height: 27px;
  color: #9c9c9c;
  margin: 0;
  padding: 0;
}
.ContactSuggestion li span {
  font-family: "Poppins", sans-serif;
  color: #9c9c9c;
  margin: 0;
  padding: 0;
}

.attach_dwnld {
  text-decoration: none !important;
}
.deleteHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 30px;
}

@media (max-width: 1088px) {
  .Mail {
    flex-direction: column;
  }

  .Maild1 {
    width: 100%;
  }

  .Maild2 {
    width: 100%;
    height: 500px;
    margin: auto;
  }
}

.Messagediv1 {
  text-align: right;
  cursor: pointer;
  margin-top: 8px;
  border-bottom: 2px solid #e8e8e8;
}

.sentMessagediv1 {
  text-align: right;
  cursor: pointer;
  margin-top: 33px;
  border-bottom: 2px solid #e8e8e8;
}

.Mailicon1,
.Mailicon2,
.Mailicon3 {
  color: #9c9c9c;
  margin-right: 15px;
}

.Mailicon1:hover {
  color: #ffe500;
}

.Mailicon2:hover {
  color: #214df9;
}

.Mailicon3:hover {
  color: #ff2934;
}

.MuiTooltip-tooltip {
  background: #f8f8f8 !important;
  color: rgba(0, 0, 0, 0.53) !important;
  font-size: 16px !important;
  margin-bottom: 4px !important;
  font-weight: 400 !important;
  font-style: normal !important;
  line-height: 24px !important;
  margin-right: -30px !important;
}

.Div1P {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 13px;
  font-style: normal;
  line-height: 27px;
  color: #9c9c9c;
  line-height: 27px;
  border-top: 2px solid #e8e8e8;
}

.Inboxdiv1P2 {
  font-weight: bolder;
  font-size: 18px;
  color: #1e1e1e;
}

.Avatardiv {
  display: flex;
  border-bottom: 1px solid #e8e8e8;
  padding: 10px 0;
  margin-bottom: 5px;
}

.ShortcutIcon {
  align-self: center;
  transform: scale(1.4) rotateY(180deg);
  margin: 0px 30px 0 10px;
  font-size: 1rem !important;
}

.Messagediv2 {
  padding: 2px;
  min-height: 70px;
}

.Div2P {
  border-bottom: 2px solid #e8e8e8;
  display: flex;
  margin: 5px 0;
}

.Div2P p {
  font-family: "Lato", sans-serif;
  margin-bottom: 0;
  color: #737574;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  margin-right: 20px;
}

.Input2 {
  border: none;
  outline: none;
  width: 100%;
  font-size: 14px;
}

.Message {
  text-align: left;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.65);
  width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.Div3 {
  margin-top: 8px;
  cursor: pointer;
  /* margin-bottom: 30px; */
}

.AtFile {
  color: #565656;
  font-size: 22px !important;
  transform: rotateZ(30deg);
}

.ImgIcon {
  margin-left: 5px;
  color: #565656;
  font-size: 23px !important;
}

.Mailsendbtn {
  float: right;
  background: #214df9;
  color: white;
  border: none;
  padding: 7px 15px;
  border-radius: 10px;
  font-size: 15px;
}

.Mailsendbtn:hover {
  cursor: pointer;
}

.SendIcon {
  transform: rotateZ(310deg);
  font-size: 15px !important;
  margin-left: 8px;
  margin-top: -5px;
}

.searchBox {
  border-radius: 10px;
  background-color: #d1d8ff;
  height: 42px;
  border: none;
  width: 100%;
  margin-bottom: 18px;
  text-align: center;
}

.SearchIcon:hover {
  cursor: pointer;
}

.searchBox input {
  background-color: transparent;
  border: none;
  margin-top: 6px;
  outline: none;
}

.searchBox input::placeholder {
  font-size: 15px;
  color: #9c9c9c;
  background-color: #d1d8ff;
  text-align: center;
}

.selectmsgtype {
  display: flex;
  background: transparent;
  width: 100%;
  height: 80px;
  margin-bottom: 10px;
  border: 1px solid transparent;
}

.selectmsgtype:hover {
  cursor: pointer;
}

.selectmsgtype:focus {
  background: #ffffff;
  border-radius: 10px;
  -moz-box-shadow: 0 0 5px #999;
  -webkit-box-shadow: 0 0 5px #999;
  box-shadow: 0 0 5px #999;
}

.activecontact {
  background: #ffffff;
  border-radius: 10px;
  -moz-box-shadow: 0 0 5px #999;
  -webkit-box-shadow: 0 0 5px #999;
  box-shadow: 0 0 5px #999;
}

.Avatar {
  align-self: center;
  margin-right: 10px;
}

.AvatarP1 {
  font-weight: bolder;
  font-size: 14px;
  margin-bottom: 0;
}

.AvatarP2 {
  display: none;
  margin: auto 0 auto auto;
  color: #9c9c9c;
  margin-left: auto;
  font-size: 12px;
}

.Avatard1 {
  text-align: left;
  width: 100%;
}

.Avatard2 {
  display: flex;
  margin-bottom: -5px;
  margin-top: 10px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.d1h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  color: #1e1e1e;
  margin-top: 5px;
}

.Draftd1h4 {
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  color: #ff2934;
  margin-top: 5px;
}

.d1h4Span {
  font-size: 12px;
  color: #1e1e1e;
  margin-left: auto;
  align-self: center;
  font-weight: 300;
}

.Maild1P {
  margin-top: 0px;
  color: #ababab;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 1309px) {
  .Maild1 {
    padding-top: 10px;
  }

  .Maild2 {
    margin-top: 0;
  }
}

@media screen and (min-width: 900px) {
  .AvatarP2 {
    display: block;
  }
}

@media screen and (max-width: 900px) {
  .MessageHead {
    margin: 0px;
  }
  .deleteHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
  }
}

