@import url("https://fonts.googleapis.com/css2?family=Lato&family=Poppins:wght@500&family=Roboto&family=Rubik&display=swap");

.tchrstdnt {
  position: relative;
  width: 93%;
  margin: auto;
  margin-top: 5px;
}
.stdntavtr {
  margin: auto;
}
.stdntavtr > span {
  font-size: 42px;
}
.stdntprofilebtn {
  background: #d10707;
  color: white;
  border-radius: 5px;
  width: 100%;
  margin-top: 50px;
  padding: 10px 15px;
  border: none;
}
.stdntprofilebtn:hover {
  cursor: pointer;
}
.tchrprofilebackbtn {
  height: 30px;
  font-size: 20px;
  margin: 20px;
  display: flex;
  border: none;
  background: transparent;
}

.tchrprofilebackbtn:hover {
  cursor: pointer;
}

.profilediv1 {
  margin: 24px 0px;
  text-align: left;
  width: 100%;
}

.profilediv2 {
  margin: 24px 0px;
  text-align: left;
  width: 100%;
}

.textfieldprofile {
  width: 25%;
  margin: 0px 2% !important;
}

.textfieldprofile2 {
  width: 83%;
  margin: 0px 2% !important;
}
.studentprofile {
  display: flex;
  background-color: #f8f9ff;
}

.studentaccount {
  min-width: 150px;
  width: 35%;
  height: screen;
  text-align: left;
  padding: 5px 10px;
  align-items: center;
  background-color: #ffffff;
}

.stdnttabbox {
  text-align: center;
}

.stdnttabs > .MuiTabs-root > .MuiTabs-fixed > .MuiTabs-flexContainer {
  border: 1px solid #b158e8;
  width: max-content;
  margin: auto;
  border-radius: 10px;
  padding: 0;
  margin-top: 25px;
}

.stdnttabs {
  display: flex;
}

.stdnttab1 {
  color: #8a8a8a;
}

.stdnttab2 {
  background-color: #b158e8 !important;
  color: white !important;
  border-radius: 10px !important;
}

#simple-tab-0:focus,
#simple-tab-1:focus,
#simple-tab-2:focus {
  color: white;
}

#simple-tab-0,
#simple-tab-1,
#simple-tab-2 {
  color: #8a8a8a;
}

.MuiTabs-indicator {
  display: none;
}

.profilebackbtn {
  margin-left: auto;
  font-size: 18px;
  line-height: 21px;
  border: none;
  background: transparent;
  position: absolute;
}

.profilebackbtn:hover {
  cursor: pointer;
}

@media (max-width: 1000px) {
  .studentprofile {
    flex-direction: column;
    width: 100%;
  }

  .studentaccount {
    width: 100%;
  }

  .profilediv1 {
    display: flex;
    justify-content: center;
  }

  .profilediv2 {
    display: flex;
    justify-content: center;
  }

  .textfieldprofile {
    width: 30%;
  }

  .textfieldprofile2 {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .profilediv1 {
    display: block;
    margin: 0;
  }

  .textfieldprofile,
  .textfieldprofile2,
  .profilediv2 {
    width: 100%;
    margin: 0 0 10px 0 !important;
  }
}

@media (max-width: 1000px) {
  .tchrprofilebackbtn {
    right: 0px;
    margin-top: 24px;
    position: absolute;
  }
}
