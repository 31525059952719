/* .tchrhome{
    position: relative;
    background-color: #F8F9FF;
    margin-top: 5px;
    display: flex;
    margin-left: 20px;
} */
.div1 {
    height: 350px;
    display: flex;
}

.div2 {
    height: 450px;
    display: flex;
    padding-top: 1.5%;
    padding-bottom: 14px;
}

.attendancebtns {
    margin-left: auto;
    margin-right: 1px;
}

.tchrbtn {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(234, 234, 234, 0.28) 100%);
    height: 7vh;

    font-size: 16px;
    border: 1px solid #8B8B8B;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
}

.tchrbtn:hover {
    cursor: pointer;
    background-color: #204DF9;
    color: white;

}

.main__container {
    display: flex;
    padding: 0px;
    gap: 2;
    width: 100%;
    background-color: #F8F9FF;
}

.left__container {
    width: 22%;
    background-color: #fff;
    padding: 5px;
    height: 100%;
    margin-bottom: 20px;
}

.right__container {
    height: 100%;
    width: 78%;
    padding: 20px 10px;
    /* margin-left: 20px;
    margin-right: 20px; */
}

@media (max-width: 1080px) {
    .left__container {
        width: 30%;
    }

    .right__container {
        width: 70%;
    }
}

@media (max-width: 720px) {
    .left__container {
        margin-left: 10px;
    }

    .right__container {
        margin-right: 0;
    }
}

@media (max-width:578px) {
    .main__container {
        flex-direction: column;
        gap: 20px;
        justify-content: center;

    }

    .left__container {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
    }

    .right__container {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
    }
}

@media (max-width:1080px) {
    .tchrhome {
        flex-direction: column;
    }

    .div1,
    .div2 {
        flex-direction: column;
        height: auto;
    }

    .div2 {
        padding-top: 0px;
    }
}