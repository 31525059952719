.boxstyle{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    border: none;
    box-shadow: 24;
    padding: 0px 20px 20px 20px;
}

.modalbutton{
    border:none; 
    margin-left: auto;
    margin-right: 0;
    margin-top: 15px;
    margin-bottom: auto;
    border-radius:50%;
    height:30px;
    width:30px;
    font-size: 18px;
    text-align: center;
    background-color:#FF2934;
    color:white;
}
.modalbutton:hover{
    cursor: pointer;
}
.label{
    color: #494949;
}
.input1{
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 15px;
    font-size: 16px;
    color: #494949;
    padding: 2px 15px;
}
.input2{
    height: 100px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 15px;
    font-size: 16px;
    color: #494949;
    padding: 10px 15px;
    text-align: start;
}
.prioritybtn{
    background: #F0F0F0;
    border-radius: 10px;
    color: #494949;
    padding: 10px 30px;
    margin:auto;
    border: none;
}
.prioritybtn:focus{
    background-color: #214DF9;
    color: white;
}
.addbtn{
    background-color: #3CB532;
    color: white;
    width: 100%;
    height: 34px;
    border: none;
}
.addbtn:hover{
    cursor: pointer;
}
.clndr-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.clndr{
    height: '100%'
}
.PrivatePickersSlideTransition-root{
    min-height: 200px !important;
}
