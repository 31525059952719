.examdiv {
    width: 90%;
    margin: 28px auto auto 50px;
}

.content {
    margin: 60px 0;
}

.examdiv p {
    font-size: 14px;
    color: #4D4D4D;
}

@media (max-width:720px) {
    .examdiv {
        width: 100%;
        margin: 0;
        padding: 28px;
    }
}

@media (max-width:400px) {
    .examdiv {
        width: 100%;
        margin: 0;
        padding: 28px 10px;
    }
}

/* .dropdown-toggle::after {
    display: none;
} */