@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap");

.time-table {
  margin: 20px 0;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  -moz-box-shadow: 0 0 5px #999;
  -webkit-box-shadow: 0 0 5px #999;
  box-shadow: 0px 4px 4px 3px rgba(240, 240, 240, 0.39);
  border-radius: 10px 10px;
  /* position: relative; */
}

/* .timetabledate{
    margin-right: 2px;
    margin-left: auto;
    display: inline-flex; 
    align-items: center; 
    border: none;
    font-size: 14px;
    padding: 5px;
} */

.date {
  width: 90px;
  background-color: #e0dddd;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  color: #414141;
  font-family: Rubik;
}

.date:hover {
  cursor: pointer;
}

.react-datepicker-ignore-onclickoutside:focus {
  outline: none !important;
}

.react-datepicker-popper {
  transform: none !important;
  z-index: 2 !important;
  margin-top: 19px;
  margin-left: 35px;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__month-container {
  width: 215px !important;
}

.react-datepicker__header {
  background-color: #fff !important;
  padding: 5px 0 !important;
  border-bottom: none !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 139.69%;
  letter-spacing: 0.15em;
  color: #555555;
  margin: 0.1rem;
}

.react-datepicker__current-month {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.react-datepicker__navigation-icon {
  color: black !important;
}

.react-datepicker__navigation-icon--next {
  color: black !important;
}

.react-datepicker__day--selected {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 139.69%;
  letter-spacing: 0.04em;
  color: #ffffff;
  background-color: #4f3fb1;
  border-radius: 50% !important;
}

.react-datepicker__day--outside-month {
  color: #d7d7d7 !important;
}

/* .timetabledate:hover{
    cursor: pointer;
}
.timetabledate:focus{
    background-color: #E0DDDD; 
    border-radius: 5px; 
    box-shadow: 0px 1px 5px rgba(33, 33, 33, 0.48); 
} */
.timetablediv-1 {
  /* background-color: #F9F9F9;  */
  /* height: 60px;  */
  /* align-items: center;  */
  width: 100%;
}

.ttd1d1 {
  padding: 8px;
  align-items: center;
  /* display: flex; */
  border-radius: 9px 9px 0px 0px;
  background-color: #f9f9f9;
}

.ttd1d1span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.ttd1d2 {
  /* padding: 8px 12px; */
  /* margin-left: 30px; */
  float: right;
  background: #e0dddd;
  box-shadow: 0px 1px 5px rgba(33, 33, 33, 0.48);
  border-radius: 5px;
}

.timetablediv2 {
  padding: 12px 6px;
}

.ttd2d1 {
  margin-bottom: -6px;
  display: flex;
  border: 1px solid #dbdbdb;
  background-color: #fcfcfc;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(219, 210, 210, 0.18);
  box-sizing: border-box;
  padding: 8px 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.ttd2d1 span:nth-child(2) {
  margin-right: 1px;
  margin-left: auto;
  align-items: center;
  padding-left: 5px;
  border-radius: 50%;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.34);
  height: 20px;
  width: 20px;
}

.lunchb {
  margin-bottom: -6px;
  border: 1px solid #dbdbdb;
  text-align: center;
  background-color: #fcfcfc;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(219, 210, 210, 0.18);
  box-sizing: border-box;
  height: auto;
  padding: 8px 12px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.lunch {
  display: flex;
  border: 1px solid #dbdbdb;
  background-color: #214df9;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(219, 210, 210, 0.18);
  box-sizing: border-box;
  height: auto;
  padding: 8px 12px;
  margin-bottom: -6px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: white;
  -moz-box-shadow: 0 0 5px #214df9;
  -webkit-box-shadow: 0 0 5px #214df9;
  box-shadow: 0 0 5px #214df9;
}

.lunch>div {
  display: flex;
  margin-left: auto;
  margin-right: 1px;
}

/* .react-datepicker-popper {
    top: auto;
    left: auto;
    transform: translate3d(218px, 365px, 0px) !important;
} */

/* @media (max-width: 1080px) {
    .timetable{
        min-width: 160px;
    }
    .timetablediv1>div{
        flex-direction: column;
    }
    .timetabledate{
        margin: auto;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .lunch{
        flex-direction: column;
        height: fit-content;
    }
    .lunch>div{
        margin-left: 0px;
    }
} */
@media (max-width: 1155px) {
  .ttd1d1 {
    font-size: 14px;
    padding: 8px;
  }

  .date {
    font-size: 14px;
    width: 80px;
  }
}

@media (max-width: 1080px) {
  .date {
    font-size: 12px;
    width: 70px;
  }

  .ttd1d1 {
    font-size: 12px;
  }

  .lunch {
    font-size: 13px;
  }

  /* .css-i4bv87-MuiSvgIcon-root{
        font-size: 1.1rem !important;
    } */
}

@media (max-width: 768px) {
  .ttd1d1span {
    font-size: 16px;
    line-height: 24px;
  }
}