.sidebarbtn{
    display: none;
    padding: 5px 7px;
    color: #000;
    font-size: 20px;
    border-radius: 5px;
    margin: 50px 10px;
    z-index: 3;
    border: 2px solid black;
}

@media (max-width:720px) {
    .sidebarbtn{
        display: block;
        z-index: 3;
    }
    .sidebar{
        z-index: 4;
    }
    
    .sidebaritem:hover{
      border: none;
      cursor: pointer;
      line-height: 52px;
    }
}