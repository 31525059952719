.feesviewdetailsbtn {
  border: none;
  background: #9f8fff;
  color: white;
  padding: 8px 20px;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.accord {
  border: none !important;
  margin-bottom: 25px;
  box-shadow: none !important;
}

.SubLayout {
  border: 2px solid #a4a4a4 !important;
  border-radius: 5px !important;
  height: 70px !important;
  padding: 0 40px !important;
}

.typo {
  font-family: "Rubik", sans-serif !important;
  font-size: 18px !important;
  color: #474747;
  font-weight: 500 !important;
  font-style: normal;
  line-height: normal;
}

.SubLayout:hover {
  box-shadow: 1px 2px 5px 1px rgb(211, 211, 211);
}

.accord .details {
  border: 1px solid #a5a5a5 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 0 !important;
  margin-top: 1px !important;
  margin-bottom: 20px;
}

.tabrow {
  padding-left: 10px;
}
.tabrowcell {
  color: #545454 !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.tabbody {
  background-color: #ffffff;
  box-sizing: border-box !important;
  border-radius: 5px !important;
}

.tabbody:last-child {
  border: 0;
}

.tabrow2cell {
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #000000 !important;
}
