ul{
    max-height: 150px !important;
    padding: 0 !important;

}
li{
    font-size: 12px !important;
    text-align: center !important;
    border-bottom: 1px solid #E0DDDD !important;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
    Top: 302px !important;
}