.login-form-with-image {
  /* background-color: rgb(214, 245, 253); */
  padding: 20px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 96vh;
}

.login-form-with-image__image-container {
  width: 50%;
  height: 100vh;
}

.login-form-with-image__image {
  width: 100%;
  height: 100vh;
  /* height: auto; */
  object-fit: cover;
}

.login-form-with-image__form-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form-with-image__form {
  padding: 20px;
  text-align: center;
  width: 70%;
}

.login-form-with-image__form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.login-form-with-image__form-title {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-size: 38px;
}

.login-form-with-image__form-label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
  font-size: 20px;
}

.login-form-with-image__form-field {
  margin-bottom: 20px;
  position: relative;
}

.login-form-with-image__form-field label {
  display: block;
  margin-bottom: 5px;
}

.login-form-with-image__form-field input {
  width: 100%;
  border-bottom: 1px solid #000000 !important;
  outline: none;
  padding: 5px 0;
  font-size: 16px;
  border: none;
}

.password-field {
  position: relative;
}

.password-toggle-btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #888;
}

.login-form-with-image__form button[type="submit"] {
  background-color: #232ded;
  color: #fff;
  padding: 20px;
  border: none;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Work Sans", sans-serif;
  cursor: pointer;
  width: 80%;
  box-shadow: 0px 4px 14px 1px #949aff;
}

@media screen and (max-width: 768px) {
  .login-form-with-image__form-container {
    width: 100%;
  }


  .login-form-with-image__form {
    margin-left: 0;
    width: 90%;
  }
}

@media screen and (max-width: 830px) {
  .login-form-with-image {
    flex-direction: column;
    height: auto;
  }

  .login-form-with-image__image-container {
    width: 100%;
    height: auto;
  }

  .login-form-with-image__image {
    /* filter: blur(8px) */
    height: auto;
  }

  .login-form-with-image__form-container {
    width: 100%;
    /* position: absolute; */
    padding: 5px 20px;
    /* margin-top: 30%; */
  }

  .login-form-with-image__form {
    width: 100vh;
  }

  .login-form-with-image__form-field input {
    width: 100%;
    max-width: 100%;
  }

  .password-toggle-btn {
    right: 5%;
  }

  .login-form-with-image__form button[type="submit"] {
    padding: 20px;
  }
}