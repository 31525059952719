.main {
    margin-left: 50px;
}

.heading {
    font-size: 14px;
    color: #000000;
    font-weight: 500;
    font-style: normal;
    line-height: 21px;
}

.tchrmail {
    display: flex;
    width: 100%;
    gap: 5px;
}

.tchrmaild1 {
    width: 20%;
    min-width: 150px;
    height: 100%;
    text-align: left;
    padding-right: 15px;
    padding-top: 20px;
    align-items: center;
    background-color: #FFFFFF;
}

.mailbox {
    width: 100%;
}

.tabs {
    margin: auto;
    min-width: 200px;
}

.composeMail {
    background-color: #214DF9 !important;
    color: white !important;
    justify-content: left !important;
    min-height: 45px !important;
    padding: 5px 30px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    border-radius: 10px !important;
    margin-bottom: 15px !important;
    text-transform: capitalize !important;
}

.TabIcon {
    font-size: 20px;
    vertical-align: middle;
}

.inboxtab {
    color: #9C9C9C !important;
    justify-content: left !important;
    min-height: 45px !important;
    padding: 5px 30px !important;
    font-size: 16px !important;
    margin-bottom: 10px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
}

.inboxtab:focus {
    color: #214DF9 !important;
    border-radius: 10px;

}

.inboxcount {
    position: absolute;
    right: 2px;
    background-color: #214DF9;
    color: white;
    border-radius: 5px;
    padding: 3px 8px;
    bottom: 9px;

}

.mailtabs {
    color: #9C9C9C !important;
    justify-content: left !important;
    min-height: 45px !important;
    padding: 5px 30px !important;
    font-size: 16px !important;
    margin-bottom: 10px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
}


.mailtabs:focus {
    color: #214DF9 !important;
    border-radius: 10px;

}

.activeTab {
    color: #214DF9 !important;
}
  

.tchrmaild2 {
    width: 80%;
}

.sidebarbtn {
    display: none;
    z-index: 3;
    width: 30%;
}

@media (max-width:1309px) {
    .tchrmail {
        display: block;
    }

    .sidebarbtn {
        display: block;
        background-color: #fff;
        font-size: 20px;
        z-index: 3;
        border: none;
    }

    .tchrmaild2 {
        width: 100%;
    }
}

@media (max-width: 1088px) {
    .main {
        margin-right: 50px;
    }
}

@media (max-width: 368px) {
    .main {
        margin-right: 30px;
        margin-left: 30px;
    }
}

.googlebtn {
    cursor: pointer;
    transition: background-color .3s, box-shadow .3s;
    
    width: 100%;
    margin: auto;

    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    
    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;
}

.googlebtn:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.googlebtn:active {
    background-color: #eeeeee;
}

.googlebtn:active {
    outline: none;
        box-shadow: 
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
}

.googlebtn:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
}