@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');

.attendance {
    width: 40%;
    height: 300px;
    /* min-width: 330px; */
    border: 1px solid #E7E7E7;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #FFFFFF;
    position: static;
    -moz-box-shadow: 0 0 5px #999;
    -webkit-box-shadow: 0 0 5px #999;
    box-shadow: 0 0 5px #999;
}

.atdiv1 {
    background-color: #F9F9F9;
    height: 50px;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgb(235, 234, 234);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.attendanceheader {
    padding: 12px 10px;
    align-items: center;
    display: flex;
}

.attendanceheader span {
    font-family: sans-serif;
    font-style: normal;
    font-weight: bolder;
    font-size: 17px;
    margin-right: 5px;
}

.attendancebtns {
    margin-right: 0px;
    margin-left: auto;
    align-items: center;
    display: inline-flex;
    font-size: 15px;
}



.custom-pie-series {
    /* filter: drop-shadow(7px 6px 28px rgba(0, 0, 0, 0.24)); */
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.99)) !important;
    /* filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.9)); */
}



.weekly:hover {
    cursor: pointer;
}

.weekly:focus {
    background-color: #E0DDDD;
    border-radius: 5px;
    box-shadow: 0px 1px 5px rgba(33, 33, 33, 0.48);
    padding: 4px;
    outline: none;
    color: #414141;
    font-weight: 500;
}

.weekly {
    border: none;
    font-size: 13px;
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    font-family: Rubik;
    font-weight: 400;
}

@media (max-width:1080px) {
    .attendance {
        width: 100%;
        height: 380px;
    }
}

@media (max-width: 350px) {
    .attendanceheader {
        padding: 10px 5px 0px 5px;
    }

    .attendanceheader span {
        font-size: 14px;
    }

    .weekly {
        font-size: 10px;
        font-weight: 600;
        padding: 0px 2px;
    }
}