.mailbox{
    width: 100%;
}
.prncplmail{
    position: relative;
    background-color: #F8F9FF;
    margin: auto;
    margin-top: 5px;
    display: flex;
    height: auto;
    min-height: 90%;
}
.prncplmaild1{
    width: 20%;
    padding-left:40px !important;
    padding-right:25px !important; 
    min-width:150px;
    height: 100%;
    text-align: left;
    padding: 0px 10px;
    align-items: center;
    background-color: #FFFFFF;
}
.prncplmaild2{
    width: 80%;
}
.mailtabs{
    color: #9C9C9C !important;
    justify-content: left !important;
    min-height: 45px !important;
    padding: 5px 25px !important;
    font-size: 16px;
    margin-bottom: 10px !important;
    font-weight: 600 !important;
}
.mailtabs:focus{
    color: #214DF9 !important;
    padding: 0px 15px !important;
    border-radius: 10px;
    padding: 5px 25px !important;
}
.composeMail{
    background-color: #214DF9 !important;
    color: white !important;
    justify-content: left !important;
    min-height: 45px !important;
    padding: 5px 25px !important;
    font-size: 16px;
    font-weight: 600 !important;
    border-radius: 10px !important;
    margin-bottom: 15px !important;
}
@media (max-width:990px) {
    .prncplmail{
        flex-direction: column;
    }
    .prncplmaild1{
        width: 95%;
        padding: 0 !important;
    }
    .prncplmaild2{
        width: 90%;
    }
}