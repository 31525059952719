@import url("https://fonts.googleapis.com/css2?family=Lato&family=Poppins:wght@100;200;600&family=Rubik:wght@400&display=swap");

.main {
  width: 90%;
  margin: 28px auto auto 50px;
}
.heading {
  color: #4d4d4d;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}
.noticediv {
  /* margin-top: 62px;  */
  margin-top: 20px;
  display: flex;
  /* margin-bottom:50px;  */
}
.addnoticebtn {
  margin-left: auto;
  border: none;
  border-radius: 5px;
  background: #214df9;
  color: white;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
}
.addicon {
  vertical-align: middle;
  margin-right: 3px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
}
.box {
  background: #ffffff;
  width: 465px !important;
}
.boxdiv {
  background: #fbfbfb;
  display: flex;
  padding: 10px 10px;
}
.boxdivh3 {
  margin-top: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #494949;
}
.crossbtn {
  border: none;
  margin-left: auto;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  font-size: 16px;
  text-align: center;
  background: #ff2934;
  color: white;
  cursor: pointer;
}
.lable {
  color: #494949;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  font-style: normal;
  font-family: Poppins;
}
.forminput {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 16px;
  letter-spacing: 0.7px;
  background: #ffffff;
}
.formtextarea {
  width: 100%;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 16px;
  letter-spacing: 0.7px;
  background: #ffffff;
}
.errorcolor {
  color: red;
}
.formselect {
  width: 120px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #ffffff;
}
.formbtn {
  width: 100%;
  text-align: center;
}
.upload {
  /* margin-left: 20%; */
  width: 220px;
  margin-top: 30px;
  margin-bottom: 15px;
  padding: 8px;
  background: #214df9;
  color: white;
  border: none;
  cursor: pointer;
}
.savebtn {
  /* margin-left: 20%; */
  width: 220px;
  margin-top: 30px;
  margin-bottom: 15px;
  padding: 8px;
  background: #3CB532;
  color: white;
  border: none;
  cursor: pointer;
}

.noticebtn {
  background-color: #ececec;
  border: none;
  border-radius: 5px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #8e8a8a;
  padding: 8px 20px;
  margin: 5px;
  height: auto;
}
.area {
  padding: 30px 30px 30px 20px;
  width: 100%;
  overflow-y: auto;
  height: 490px;
  margin: 0;
  scroll-behavior: smooth;
}

.area div {
  background-color: #fdfdfd;
  margin-bottom: 30px;
}

.area div p {
  font-family: Rubik;
  font-size: 15px;
  color: #292929;
}

.area div p:nth-child(1) {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #292929;
}

.area div p:nth-child(2) {
  color: #6755d9 !important;
  font-family: Lato !important;
  font-weight: 700 !important;
  font-style: normal;
  font-size: 16px;
  line-height: 25px;
}

.area div p:nth-child(3) {
  color: #000000 !important;
  font-family: Lato !important;
  font-weight: 400 !important;
  font-style: normal;
  font-size: 16px;
  line-height: 25px;
  word-wrap: break-word;
}

.noticebtn:hover {
  cursor: pointer;
}
.noticebtnfocus {
  background: #1bbf9c;
  color: #ffffff;
}
.noticedivD1 {
  width: 50%;
  height: 510px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 2px 7px rgba(52, 52, 52, 0.35);
  border-radius: 5px;
  overflow-y: hidden !important;
}
.clas {
  height: auto;
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;
}
.head {
  margin: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  padding-left: 5px;
}
.button {
  margin-left: auto;
  margin-right: 10px;
}
.noticedivD2 {
  height: 320px;
  width: 40%;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 2px 7px rgba(52, 52, 52, 0.35);
  border-radius: 5px;
  margin-left: 78px;
}
.principal {
  height: auto;
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;
}

.principal p {
  margin: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: black;
  padding-left: 5px;
  padding-top: 5px;
}

.principal button {
  background: #e0dddd;
  border: none;
  box-shadow: 0px 1px 5px rgba(33, 33, 33, 0.28);
  border-radius: 5px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  padding: 0 20px 0 20px;
  margin-right: 20px;
  color: #414141;
}
.msg {
  /* margin-top: 15px; */
  display: flex;
}
.avatar {
  max-height: 100%;
  margin: 22px 20px;
}
.messagepara {
  /* margin-top: 0px; */
  padding-top: 15px;
  width: 100%;
  height: 272px;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.messagepara p {
  /* margin-top: -20px; */
  padding: 0px 2px;
  font-family: Poppins;
  font-style: normal;
  font-size: 16px;
  line-height: 25px;
}
@media (max-width: 1080px) {
  .main {
    width: 100%;
    margin: 0;
    padding: 28px;
  }
  .noticediv {
    flex-direction: column;
  }
  .noticedivD1 {
    width: 100%;
  }
  .noticedivD2 {
    height: 420px;
    width: 100%;
    margin-left: 0;
    margin-top: 25px;
  }
  .messagepara {
    height: 372px;
  }
}
@media (max-width: 720px) {
  .messagepara p{
    padding-right: 10px;
  }
}
@media (max-width: 500px) {
  .main {
    width: 100%;
    margin: 0;
    padding: 20px;
  }
  .box {
    width: 325px !important;
  }
}