.importinput {
  display: none;
}

.importinput::before {
  content: "Import File";
}

.importlabel {
  color: #b420f9;
  border: 1px solid #b420f9;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 16px;
  margin: 25px;
  margin-left: auto;
}

.exportlabel {
  color: #5647b2;
  border: 1px solid #5647b2;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 16px;
}

.importlabel:hover,
.exportlabel:hover {
  cursor: pointer;
}

.backbtn {
  font-size: 18px;
  line-height: 21px;
  position: absolute;
  right: 0;
  top: 0;
}

.backbtn > button {
  border: none;
  background: none;
  font-size: 18px;
  color: #414141;
}

.backbtn > button:hover {
  cursor: pointer;
}

.verifybtn {
  background: #309d1e;
  color: white;
  border-radius: 5px;
  margin: 25px;
  padding: 8px 20px;
  font-size: 18px;
  border: none;
}

.savendclosebtn {
  background: #214df9;
  color: white;
  border-radius: 5px;
  padding: 8px 20px;
  font-size: 18px;
  border: none;
}

.verifybtn:hover,
.savendclosebtn:hover {
  cursor: pointer;
}

.entermarksbtn {
  background: #9f8fff;
  color: white;
  padding: 6px 12px;
  border: none;
  font-size: 15px;
}

.entermarksbtn:hover {
  cursor: pointer;
}

.fixedcell {
  position: sticky;
  left: 0;
  z-index: 1;
}

.fixedcell2 {
  position: sticky;
  left: 40px;
  z-index: 1;
}

.scrollablecontainer {
  overflow-x: auto;
}

.clear-btn {
  color: #214df9;
  cursor: pointer;
}

.clear-btn:hover {
  text-decoration: underline;
}
