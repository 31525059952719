.PrivatePickersSlideTransition-root{
    min-height: 195px !important;
}
.boxstyle{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    background-color: white;
    border: none;
    box-shadow: 24;
    padding: 0px 20px 20px 20px;
}