.main {
  width: 90%;
  margin: 28px auto auto 50px;
}

.heading {
  color: #4d4d4d;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

.gtr {
  font-size: 16px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  font-weight: 800;
}

.heading:nth-child(2) {
  font-weight: 700;
  text-decoration-line: underline;
}

.noticediv {
  margin-top: 62px;
  display: flex;
  margin-bottom: 50px;
}

.noticebtn {
  background-color: #ececec;
  border: none;
  border-radius: 5px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #8e8a8a;
  padding: 8px 20px;
  margin: 5px;
  height: auto;
}

.noticebtnfocus {
  background: #1bbf9c;
  color: #ffffff;
}

.area {
  padding:30px 30px 30px 20px;
  width: 100%;
  overflow-y: auto;
  height: 490px;
  margin:0;
  scroll-behavior: smooth;
}

.area div {
  background-color: #fdfdfd;
  margin-bottom: 30px;
}

.area div p {
  font-family: Rubik;
  font-size: 15px;
  color: #292929;
}

.area div p:nth-child(1) {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #292929;
}

.area div p:nth-child(2) {
  color: #6755d9 !important;
  font-family: Lato !important;
  font-weight: 700 !important;
  font-style: normal;
  font-size: 16px;
  line-height: 25px;
}

.area div p:nth-child(3) {
  color: #000000 !important;
  font-family: Lato !important;
  font-weight: 400 !important;
  font-style: normal;
  font-size: 16px;
  line-height: 25px;
  word-wrap: break-word;
}

.noticedivD1 {
  width: 50%;
  height: 550px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 2px 7px rgba(52, 52, 52, 0.35);
  border-radius: 5px;
  overflow-y: hidden !important;
}

.clas {
  height: auto;
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;
}

.head {
  /* margin-left: 17px; */
  margin: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  padding-left: 5px;
}

.button {
  margin-left: auto;
  font-weight: 500;
  font-family: Rubik;
  padding: 0 5px;
  /* margin-right: 10px; */
}

.noticedivD2 {
  height: 320px;
  width: 40%;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 2px 7px rgba(52, 52, 52, 0.35);
  border-radius: 5px;
  margin-left: 78px;
}

.principal {
  height: auto;
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 4px 4px 0px 0px;
  padding: 5px 10px;
  border-bottom: 1px solid #e1e1e1;
}

.principal p {
  margin: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: black;
  padding-left: 5px;
  padding-top: 5px;
}

.msg {
  /* margin-top: 15px; */
  display: flex;
}

.avatar {
  max-height: 100%;
  margin: 22px 20px;
}

.messagepara {
  /* margin-top: 0px; */
  padding-top: 15px;
  width: 100%;
  height: 272px;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.messagepara p {
  margin-top: -20px;
  padding: 0px 2px;
  font-family: Poppins;
  font-style: normal;
  font-size: 16px;
  line-height: 25px;
}

@media (max-width: 1080px) {
  .main {
    width: 100%;
    margin: 0;
    padding: 28px;
  }
  
  .noticediv {
    flex-direction: column;
  }

  .noticedivD1 {
    width: 100%;
  }

  .noticedivD2 {
    height: 420px;
    width: 100%;
    margin-left: 0;
    margin-top: 25px;
  }

  .messagepara {
    height: 372px;
  }
}

@media (max-width: 720px) {
  .messagepara p{
    padding-right: 10px;
  }
}

@media (max-width: 500px) {
  .main {
    width: 100%;
    margin: 0;
    padding: 20px;
  }
  .box {
    width: 325px !important;
  }
}
