.sidebarbtn {
  display: none;
  padding: 5px 7px;
  background-color: #fff;
  font-size: 20px;
  margin: 33px 10px;
  z-index: 3;
  border: none !important;
}
.styleitem {
  position: relative;
  height: 88px !important;
  align-items: flex-start !important;
  /* margin-top: -15px;
  margin-bottom: -33px; */
}
.logoutbtn:hover {
  cursor: pointer;
}
.logoutbtn {
  display: flex !important;
}

.logo-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  margin-top: 8px;
}

.logo-company {
  text-align: center;
}
@media (max-width: 851px) {
  .logoutbtn {
    display: none !important;
  }
}

@media (max-width: 720px) {
  .sidebarbtn {
    display: block;
    z-index: 3;
    margin: 0 !important;
    padding-top: 34px !important;
    padding-bottom: 33px !important;
    padding-left: 12px !important;
    padding-right: 10px !important;
  }
  .sidebar {
    z-index: 4;
  }

  .sidebaritem:hover {
    border: none;
    cursor: pointer;
    line-height: 52px;
  }
  .logo-text {
    margin-bottom: 0px;
  }

  .logo-company {
    margin-top: 92px;
  }

  .sidebardiv {
    overflow-y: auto;
  }

  .logoutbtn {
    display: none !important;
  }
  .Sidebarlist {
    margin-bottom: 15px;
  }
}
