.homeworkassign {
  width: 65%;
  height: 100%;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: 10px;
  -moz-box-shadow: 0 0 5px #999;
  -webkit-box-shadow: 0 0 5px #999;
  box-shadow: 0 0 5px #999;
  /* padding: 2px; */
  background-color: #ffffff;
  overflow-y: auto;
}
.modelbtn {
  margin-right: 0px;
  margin-left: auto;
  border: none;
  padding: 0px 10px;
  align-items: center;
  font-size: 24px;
  text-align: right;
  color: #6d6d6d;
  background-color: #e0dddd;
  box-shadow: 0px 1px 5px rgba(33, 33, 33, 0.48);
}
.modalbutton {
  border: none;
  margin-left: auto;
  margin-right: 0;
  margin-top: 15px;
  margin-bottom: auto;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  font-size: 18px;
  text-align: center;
  background-color: #ff2934;
  color: white;
}
.modalbutton:hover {
  cursor: pointer;
}

.homeworksection {
  width: 13.9375rem;
  height: 10.75rem;
  flex-shrink: 0;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  cursor: pointer;
}

.modelbody {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1080px) {
  .homeworkassign {
    height: 380px;
    width: 100%;
    display: block;
    /* margin-left: 34px; */
  }
}
