.subjectsContainer {
  position: relative;
  padding: 20px 80px 20px 40px;
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.inputBox {
  height: 100%;
  width: 100%;
  padding: 0px 10px;
  color: rgba(0, 0, 0, 0.65);
  border: none;
  outline: none;
  user-select: none;
  font-family: Inter;
  font-size: 22px;
  font-weight: 400;
}

input::placeholder {
  font-family: Inter;
  font-size: 22px;
  font-weight: 400;
  font-style: italic;
  color: #909090;
  opacity: 0.65;
}

.classAcademicFees, .academicYear, .feesType, .startEndDate {
  height: 50px;
  width: 50%;
}


@media (min-width: 1280px) {
  .classAcademicFees {
    height: 50px;
    width: 207px;
  }

  .academicYear {
    height: 50px;
    width: 207px;
  }

  .feesType {
    height: 50px;
    width: 346px;
  }

  .startEndDate {
    height: 50px;
    width: 300px;
    position: relative;
  }

  .dueDate {
    height: 50px;
    width: 250px;
    position: relative;
  }

  .amount {
    height: 50px;
    width: 200px;
  }

  .latePayment {
    height: 50px;
    width: 250px;
  }

  .afterDueDate {
    height: 50px;
    width: 250px;
  }

  .timePeriod {
    position: relative;
    height: 50px;
    width: 270px;
  }
}
