.main {
    width: 90%;
    margin: 28px auto auto 50px;
}

.para {
    font-size: 14px;
    color: #4D4D4D;
}

.teacherdiv {
    margin-top: 62px;
    margin-bottom: 50px;
    margin-left: 80px;
    display: flex;
    flex-wrap: wrap;
}

.teacherdiv1 {
    margin-bottom: 50px;
    margin-right: 72px;
    width: 18%;
    min-height: 280px;
    background: #FDFDFD;
    box-shadow: 0px 2px 10px 5px rgba(161, 161, 161, 0.25);
    border-radius: 10px;
    text-align: center;
    padding-bottom: 20px;
}

.teacherdiv2 {
    max-height: 110px;
    text-align: center;
    padding-top: 22px;
}

.teacherdiv2style {
    max-height: 100%;
    margin: auto;
}

.para2 {
    margin-bottom: 0px;
    font-family: Poppins !important;
    font-weight: 500 !important;
    font-size: 18px;
    color: #000000;
}

.para3 {
    font-family: Rubik !important;
    font-weight: 400 !important;
    font-size: 14px;
    color: #000000;
}

.para4 {
    font-family: Rubik !important;
    font-weight: 400 !important;
    font-size: 12px;
    color: #000000;
    padding: 0px 15px;
    line-height: 18px;
    overflow: hidden;
}

.feedbackbtn1 {
    background: #ECECEC;
    border-radius: 6px;
    font-family: Roboto !important;
    border: none;
    font-weight: 500 !important;
    font-size: 12px;
    color: #8E8A8A;
    padding: 13px 20px;
    cursor: not-allowed;
}

.feedbackbtn {
    background-color: #214DF9;
    color: white;
    border-radius: 6px;
    font-family: Roboto !important;
    border: none;
    font-weight: 500 !important;
    font-size: 12px;
    padding: 13px 20px;
}



.feedbackbtn2 {
    background-color: #3CB532;
    color: white;
    border-radius: 6px;
    font-family: Roboto !important;
    border: none;
    font-weight: 500 !important;
    font-size: 12px;
    padding: 13px 20px;
}

.feedbackbtn:hover {
    cursor: pointer;
}

/* .feedbackbtn:focus{
    background-color: #214DF9;
    color: white;
} */
@media (max-width:1282px) {
    .teacherdiv1 {
        width: 25%;
    }
}

@media (max-width:1100px) {
    .teacherdiv1 {
        margin-right: 60px;
        margin-bottom: 40px;
    }
}

@media (max-width:940px) {
    .teacherdiv1 {
        margin-right: 50px;
        margin-bottom: 30px;
    }
}

@media (max-width:815px) {
    .teacherdiv1 {
        width: 30%;
        margin-right: 70px;
    }
}

@media (min-width:721px) and (max-width:1080px) {
    .teacherbr {
        display: block;
    }
}

@media (max-width:720px) {
    .teacherdiv {
        flex-direction: column;
        margin-left: 0px;
    }

    .teacherdiv1 {
        width: 70%;
        margin: auto;
        margin-bottom: 40px;
    }

    .main {
        width: 100%;
        padding: 28px;
        margin: 0;
    }
}

@media (max-width :334px) {
    .teacherdiv1 {
        width: 100%;
    }
}