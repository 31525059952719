.containerBox {
  width: calc(100% - 140px);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  overflow: scroll;
}

.topNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
}

.breadcrumb {
  display: flex;
  align-items: center;
  color: #4d4d4d;
}

.breadcrumbText {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 400;
}

.breadcrumbText.underline {
  text-decoration: underline;
}

.contentBox {
  display: flex;
}

.leftContainer {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.profileImageContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.profilePlaceholder {
  height: 132px;
  width: 132px;
  border-radius: 50%;
  background-color: #f5f5f5;
  display: grid;
  place-content: center;
  font-family: "Rubik";
  font-weight: 500;
  font-size: 48px;
  color: #959595;
}

.rightContainer {
  width: 73%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.profileDetails {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profileRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
}

.profileRow strong {
  font-weight: 600;
  color: #000;
}

.backButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.backButtonText {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 24px;
  color: #414141;
}

.field-label {
  font-weight: 600;
  font-size: 18px;
  color: #000;
}

.field-value {
  font-weight: 400;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
  padding: 5px;
}
