/* The logo image for the app */
.App-logo {
  height: 40vmin;
  pointer-events: none; /* Prevents clicks on the logo */
}

/* Spinning animation for the logo, only when motion is not reduced */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Styles for the main header of the app */
.App-header {
  background-color: var(--header-background-color); /* Uses a CSS variable */
  min-height: 100vh; /* Minimum height of the header */
  display: flex; /* Displays elements in a row */
  flex-direction: column; /* Stacks elements vertically */
  align-items: center; /* Centers elements horizontally */
  justify-content: center; /* Centers elements vertically */
  font-size: calc(10px + 2vmin); /* Responsive font size */
  color: white; /* Text color */
}

/* Link styles for the app */
.App-link {
  color: var(--link-color); /* Uses a CSS variable */
}

/* Spinning animation for the logo */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
