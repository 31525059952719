.topstudents-container {
  width: 65%;
  height: 100%;
  margin: auto;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: 10px;
  -moz-box-shadow: 0 0 5px #999;
  -webkit-box-shadow: 0 0 5px #999;
  box-shadow: 0 0 5px #999;
  background-color: #ffffff;
  overflow-y: hidden;
}

.topstudents-header {
  padding: 12px 10px;
  align-items: center;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
  height: 50px;
  background-color: #f9f9f9;
  border-radius: 10px 10px 0 0;
}

.topstudents-body {
  height: calc(100% - 60px); /* Subtract header height from container height */
  overflow-y: auto;
  /* padding: 8px; */
}

.topstudents-body::-webkit-scrollbar {
  width: 8px;
}

.topstudents-body::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
  overflow-x: auto;
}

@media (max-width: 1080px) {
  .topstudents-container {
    width: 100%;
    height: 380px;
    /* margin-left: 34px; */
  }
}
