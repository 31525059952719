.accordion-item {
  width: 100%;
  margin: 0 !important;
  border-radius: 4px !important;
  margin-bottom: 25px !important;
}

.accordion-button {
  background-color: transparent !important;
  border: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  /* font-size: 22px; */
  text-transform: capitalize;
  color: #646464;
  font-size: 18px;
  transition: 0.05ms;
}

.accordion-button:focus {
  box-shadow: none;
}
/* 
  .accordion-button:not(.collapsed) {
    color: #212529;
  } */

.accordion-item:hover {
  box-shadow: 1px 2px 5px 1px rgb(211, 211, 211);
}
.accordion-header {
  border: 1.5px solid #a4a4a4 !important;
  border-radius: 4px !important;
  height: 70px !important;
  color: #646464 !important;
  font-weight: bold !important;
  font-family: Rubik !important;
  font-size: 18px !important;
  box-shadow: none !important;
  margin-bottom: 0.6px !important;
}

.accordion-body {
  margin: 0;
  display: flex;
  width: 100%;
  background-color: white;
  border: 1px solid #a4a4a4;
  border-radius: 4px;
}
