.layout__main {
  display: flex;
}

.layout__leftSide {
  display: flex;
}

.layout__rightSide {
  width: 100%;
  margin-left: 118px !important;
  /* margin-bottom: 8px; */
}

@media (max-width: 720px) {
  .layout {
    width: auto;
    margin-left: 0px;
  }
  .layout__rightSide {
    margin-left: 0px !important;
  }
  .layout__main {
    display: block;
  }
}
