@import url("https://fonts.googleapis.com/css2?family=Lato&family=Poppins:wght@500&family=Roboto&family=Rubik&display=swap");

.studentattendance {
  position: relative;
  /* padding: 20px 0px; */
  padding: 20px;
}

.filtersContainer {
  display: flex;
  row-gap: 8px;
  column-gap: 22px;
  margin-top: 10px;
  /* flex-wrap: wrap; */
}

.filtersContainer .filters {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

.stdntAttndnceApplyBtn {
  background-color: #214df9;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 5px 25px;
}
.stdntAttndnceApplyBtn:hover {
  cursor: pointer;
}

.searchBar {
  display: flex;
  gap: 10px;
  background-color: white;
  border-radius: 8px;
  align-items: center;
  box-shadow: 0 2px 7px 0 rgba(52, 52, 52, 0.42);
  padding: 5px;
  max-width: 31%;
}

.searchBar input {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 300;
  text-decoration: none;
  border: none;
  outline: none;
  width: 100%;
}

.backbtn {
  font-size: 18px;
  line-height: 21px;
  position: absolute;
  right: 0;
  top: 0;
}
.backbtn > button {
  border: none;
  background: none;
  font-size: 18px;
  color: #414141;
}
.backbtn > button:hover {
  cursor: pointer;
}
.markattendancebtn {
  background-color: #3cb532;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 5px 20px;
}
.markattendancebtn:hover {
  cursor: pointer;
}

.editProfileBtn:hover {
  transform: scale(1.05);
}

@media screen and (min-width: 880px) {
  .markattendancebtn {
    margin-left: auto;
    margin-top: 0;
  }
}

@media (max-width: 880px) {
  .filtersContainer {
    column-gap: 5px;
  }

  .filters {
    gap: 5px;
  }

  .searchBar {
    display: flex;
    gap: 0px;
    background-color: white;
    border-radius: 8px;
    align-items: center;
    box-shadow: 0 2px 7px 0 rgba(52, 52, 52, 0.42);
    padding: 5px;
    max-width: 27%;
  }

  .searchBar input {
    font-size: 14px;
    font-weight: 300;
    text-decoration: none;
    border: none;
    outline: none;
    width: 100%;
  }
}

a:link { 
  text-decoration: none; 
} 
a:visited { 
  text-decoration: none; 
} 
a:hover { 
  text-decoration: none; 
} 
a:active { 
  text-decoration: none; 
}

@media (max-width: 600px) {
  .filtersContainer {
    flex-wrap: wrap;
  }
  .searchBar {
    min-width: 100%;
  }
}
